import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from '@mui/material';
import Aos from 'aos';
import { Link, Outlet } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import Footer from 'components/footer';

import logo from 'assets/images/logo.svg';
import logoShort from 'assets/images/logoShort.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import 'aos/dist/aos.css';

type NavItem = {
  label: string;
  href: string;
  newTab: boolean;
};

const drawerWidth = 240;
const navItems: NavItem[] = [
  {
    label: 'White paper',
    href: '/files/white-paper.pdf',
    newTab: true,
  },
];

function NavButton(props: NavItem) {
  const { href, label, newTab } = props;

  return (
    <Button
      sx={{ color: '#fff' }}
      component={Link}
      to={href}
      target={newTab ? '_blank' : ''}
      className="!capitalize"
    >
      {label}
    </Button>
  );
}

function DrawerNavItem(props: NavItem) {
  const { href, label, newTab } = props;
  return (
    <ListItem
      component={Link}
      disablePadding
      to={href}
      target={newTab ? '_blank' : ''}
    >
      <ListItemButton sx={{ textAlign: 'center' }}>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
}

function HomeLayout() {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (mounted) {
      Aos.init({
        once: true,
        disable: 'phone',
      });
    }
  }, [mounted]);

  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);
  if (!mounted) return null;

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderDrawer = () => (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center',
      }}
    >
      <div
        className="py-3"
        style={{
          background: isMobile
            ? 'var(--mka-primary-700)'
            : 'linear-gradient(90deg, #00B7B0 0%, #00A099 12.78%, #006159 52.52%, #004941 70.98%)',
        }}
      >
        <img
          src={logoShort}
          className="inline-block h-[30px]"
          alt="logo"
        />
      </div>

      <List>
        {navItems.map((item) => (
          <DrawerNavItem
            key={item.href}
            label={item.label}
            href={item.href}
            newTab={item.newTab}
          />
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar
        component="nav"
        style={{
          background: isMobile
            ? 'var(--mka-primary-700)'
            : 'linear-gradient(90deg, #00B7B0 0%, #00A099 12.78%, #006159 52.52%, #004941 70.98%)',
        }}
      >
        <Toolbar>
          <div className="container flex items-center justify-between mx-auto">
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
              className="!ml-[-5px]"
            >
              <FontAwesomeIcon
                icon={faBars}
                className="text-white"
              />
            </IconButton>
            <Link to="/">
              <img
                src={logo}
                className="hidden md:inline-block h-[30px]"
                alt="logo"
              />
            </Link>
            <Link to="/">
              <img
                src={logoShort}
                className="inline-block md:hidden h-[40px]"
                alt="logo"
              />
            </Link>
            <Box
              sx={{ display: { sm: 'block' } }}
              className="md:ml-auto"
            >
              <div className="hidden md:inline-block">
                {navItems.map((item) => (
                  <NavButton
                    key={item.href}
                    label={item.label}
                    href={item.href}
                    newTab={item.newTab}
                  />
                ))}
              </div>

              <Link
                to="/"
                className="bg-mka-primary-100 py-2 px-5 ml-4 rounded-full text-white font-bold uppercase"
              >
                MUA LUCK
              </Link>
            </Box>
          </div>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {renderDrawer()}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
}

export default HomeLayout;
