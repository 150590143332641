import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import iconConvert from 'assets/images/iconConvert.svg';

function Investment() {
  return (
    <div
      className="w-full"
      style={{
        background:
          'linear-gradient(180deg, #EBEBEB 0%, rgba(246, 246, 246, 0.479167) 37.5%, rgba(255, 255, 255, 0) 75%)',
      }}
    >
      <div className="container px-4 md:px-0 flex flex-wrap justify-between items-center mx-auto py-[50px] md:py-[100px] ">
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 w-full">
          <div className="col-span-3 order-2 md:order-1 flex flex-col justify-center mt-10 md:mt-0 max-w-[500px] text-center md:text-left">
            <div className="font-bold md:text-lg max-w-[300px] mx-auto md:max-w-max md:ml-0">
              Luôn giữ tỉ lệ quy đổi bền vững 1:10.000 đồng
            </div>
            <div className="mt-5">
              <Button
                component={Link}
                to="/"
                className="!bg-mka-primary !py-2 !px-4 !rounded-full !uppercase  !text-white !font-bold"
              >
                Đọc Whitepaper
              </Button>
            </div>
          </div>
          <div className="col-span-2 order-1  md:order-2">
            <img
              src={iconConvert}
              className="max-w-full h-auto"
              alt="convert"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Investment;
