import { useState } from 'react';
import iconFaqBullet from 'assets/images/iconFaqBullet.svg';
import faqBackground from 'assets/images/faqBackground.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import style from './style.module.scss';

type IAccordion = {
  id: number;
  title: string;
  content: string;
  active: number | undefined;
  setActive: (active: number) => void;
};

function AccordionItem({ id, title, content, active, setActive }: IAccordion) {
  return (
    <div className="accordion-item mb-5 border-t pt-5">
      {/* eslint-disable jsx-a11y/no-static-element-interactions */
      /* eslint-disable jsx-a11y/click-events-have-key-events */}
      <div
        className="accordion-title flex items-center cursor-pointer font-medium"
        onClick={() => setActive(id)}
      >
        <div className="mr-3">
          {active === id ? (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="rotate-180 text-mka-primary text-[20px]"
            />
          ) : (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="text-mka-primary text-[20px]"
            />
          )}
        </div>
        <div className="font-bold">{title}</div>
      </div>
      {active === id && <div className="accordion-content pl-[25px] mt-2">{content}</div>}
    </div>
  );
}

function Faq() {
  const [active, setActive] = useState<number>();

  const faqs = [
    {
      id: 1,
      title: 'LUCK stable token là gì?',
      content:
        'LUCK là một phiên bản stable coin thế hệ mới khi vừa có giá trị quy đổi bền vững với Việt Nam đồng theo tỷ lệ 1:10,000 đồng và vừa có thể đem lại lợi nhuận cho người nắm giữ khi neo theo giá trị tăng trưởng của tài sản thực.',
    },
    {
      id: 2,
      title: 'Nắm giữ LUCK có an toàn hay không?',
      content:
        'Thay vì nắm giữ tài sản thực, việc nắm giữ LUCK đem lại cho người nắm giữ tính thanh khoản linh hoạt nhưng vẫn đảm bảo an toàn. FMC LUCK đã làm việc với các đơn vị kiểm toán hàng đầu trên toàn quốc như Big4 hàng quý để thực hiện các thủ tục kiểm toán số tiền mặt dự trữ trong pool ngân hàng để đảm bảo LUCK luôn được hỗ trợ đầy đủ bởi tiền mặt.',
    },
    {
      id: 3,
      title: 'Khi nào tôi nhận được tiền sinh lời từ khoản đầu tư thông qua LUCK?',
      content:
        'Người dùng có thể nhận về lợi nhuận thông qua LUCK và hoán đổi theo tổng khối lượng được phân bổ định kỳ tương ứng với lợi nhuận của tài sản thực sau mỗi kỳ thẩm định. ',
    },
    {
      id: 4,
      title: 'Tôi có thể rút LUCK về Việt Nam đồng bằng cách nào?',
      content:
        'Bất cứ lúc nào, người sở hữu LUCK đều có thể truy cập trang web của chúng tôi để kiểm  tăng trưởng, lợi nhuận của mã thông báo LUCK đang nắm giữ và số lượng tiền mặt sẵn sàng trong hệ thống để cash-out. Trong trường hợp cần cash-out mà lượng tiền mặt tại AMC LUCK không đủ, người nắm giữ phải chờ bổ sung tại năm sau và ưu tiên giải ngân theo đăng ký.',
    },
    {
      id: 5,
      title: 'LUCK có gì khác biệt so với các stablecoin khác?',
      content:
        'LUCK là stablecoin hấp dẫn cho các nhà đầu tư và các chủ tài sản trong thị trường bất động sản. Cũng giống như những stablecoin neo bởi tiền mặt, LUCK luôn có giá trị quy đổi bền vững với Việt Nam đồng theo tỷ lệ 1:10,000 đồng. Tuy nhiên, việc nắm giữ LUCK không chỉ an toàn mà còn hấp dẫn hơn so với các stablecoin khác khi nó còn có thể đem lại lợi nhuận cho người sở hữu do còn được neo theo giá trị tăng trưởng của tài sản thực qua mỗi kỳ thẩm định.',
    },
  ];

  return (
    <div
      className="w-full bg-no-repeat bg-right"
      style={{
        backgroundImage: `url(${faqBackground})`,
      }}
    >
      <div
        className="container px-4 md:px-0 mx-auto pb-[50px]  "
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1500"
      >
        <div className={style.header}>
          <img
            src={iconFaqBullet}
            width="30px"
            height="30px"
            alt="Bullet"
            className="mt-[5px]"
          />
          <div className="header-text ml-2 text-[33px] text-mka-primary font-bold">FAQ</div>
        </div>
        <div>
          <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 w-full">
            <div className="col-span-3">
              <div className="accordion mt-10">
                {faqs.map(({ id, title, content }) => (
                  <AccordionItem
                    title={title}
                    content={content}
                    id={id}
                    key={id}
                    setActive={setActive}
                    active={active}
                  />
                ))}
              </div>
            </div>
            {/* <div className="col-span-2">
              <img
                src={faqBackground}
                alt="Bullet"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
