import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import iconSubscribe from 'assets/images/iconSubscribe.svg';
import style from './style.module.scss';

function Subscribe() {
  return (
    <div
      className="w-full "
      style={{
        background:
          'linear-gradient(180deg, rgba(95, 184, 158, 0.12) 54.69%, rgba(255, 255, 255, 0) 100%)',
      }}
    >
      <div
        className="container relative px-4 md:px-0 mx-auto pt-[150px] pb-[100px] md:py-[150px]"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div className="">
          <div className="top-[-100px] left-[50%] ml-[-100px] absolute">
            <img
              src={iconSubscribe}
              alt="Subscribe"
              className="h-[200px]"
            />
          </div>
          <div className="text-center">
            <h2 className="font-bold text-[23px] text-lg text-mka-primary leading-tight uppercase">
              Tạo ra lợi nhuận với LUCK ngay từ bây giờ
            </h2>
            <div className="mt-14">
              <div className={style.btnSubscribe}>
                <Button
                  component={Link}
                  to="/"
                  className="!text-white !bg-mka-primary !px-9 !rounded-full !py-2 !uppercase !font-bold !font-inherit"
                >
                  Đăng ký ngay
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
