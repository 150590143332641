import { Link } from 'react-router-dom';
import iconAudit from 'assets/images/iconAudit.svg';

function Audit() {
  return (
    <div className="w-full">
      <div className="container px-4 md:px-0 flex flex-wrap justify-between items-center mx-auto py-[50px] pb-[200px] md:pt-[100px] md:pb-[200px] ">
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 w-full">
          <div
            className="col-span-3 order-2 md:order-1 flex flex-col justify-center mt-10 md:mt-0 max-w-[500px] text-center md:text-left"
            data-aos="fade-right"
          >
            <div className="font-bold text-lg max-w-[350px] mx-auto md:max-w-[440px] md:ml-0">
              Được kiểm toán bởi Big4 và quản lý bởi AMC LUCK
            </div>
            <div className="mt-5">
              <Link
                to="/"
                className="bg-mka-primary py-2 px-4 rounded-full text-white uppercase font-bold"
              >
                Xem thêm
              </Link>
            </div>
          </div>
          <div
            className="col-span-2 order-1  md:order-2"
            data-aos="fade-left"
          >
            <img
              src={iconAudit}
              className="max-w-full h-auto"
              alt="audit"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Audit;
