import { Link } from 'react-router-dom';
import iconProfit from 'assets/images/iconProfit.svg';

function Profitable() {
  return (
    <div className="w-full bg-[#5FB89E]/[.12]">
      <div className="container px-4 md:px-0 flex flex-wrap justify-between items-center mx-auto py-[50px] md:py-[100px] ">
        <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4 w-full">
          <div
            className="col-span-2"
            data-aos="fade-right"
          >
            <img
              alt="profit"
              src={iconProfit}
              className="max-w-full h-auto"
            />
          </div>
          <div
            className="col-span-3 md:pl-20 flex flex-col justify-center mt-10 md:mt-0 max-w-[600px] text-center md:text-left"
            data-aos="fade-left"
          >
            <div className="font-bold text-lg max-w-[300px] mx-auto md:max-w-[400px] md:ml-0">
              Sinh lời ổn định theo giá trị tăng trưởng của tài sản thực
            </div>
            <div className="mt-5">
              <Link
                to="/"
                className="bg-mka-primary py-2 px-4 rounded-full text-white uppercase font-bold"
              >
                Xem thêm
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profitable;
