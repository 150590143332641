import {
  Hero,
  ConversionRate,
  Subscribe,
  Investment,
  Audit,
  Faq,
  Partner,
  Liquidity,
  Profitable,
} from 'components/home';

function Home() {
  return (
    <div>
      <div>
        <Hero />
      </div>
      <div>
        <ConversionRate />
      </div>
      <div>
        <Investment />
      </div>
      <div>
        <Liquidity />
      </div>
      <div>
        <Profitable />
      </div>
      <div>
        <Audit />
      </div>
      <div>
        <Subscribe />
      </div>
      <div>
        <Faq />
      </div>
      <div>
        <Partner />
      </div>
    </div>
  );
}

export default Home;
