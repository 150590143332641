import style from './style.module.scss';

function Hero() {
  return (
    <div className={`${style.wrapper} w-full relative bg-no-repeat`}>
      <div
        className="container px-4 md:px-0 pt-10 md:pt-0 flex flex-wrap justify-between md:items-center mx-auto py-[50px] md:py-0 md:min-h-[600px] text-white"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div className="grid md:grid-cols-3 gap-4 w-full">
          <div className="col-span-2 max-w-[530px] text-center md:text-left">
            <h1 className="font-semibold text-[38px] leading-tight max-w-[300px] md:max-w-max mx-auto md:ml-0 ">
              STABLE TOKEN THẾ HỆ MỚI
            </h1>
            <div className="mt-3">
              Tăng trưởng bền vững và cung cấp giải pháp đầu tư phân đoạn theo giá trị tài sản thực
              (RWA)
            </div>
            <div className="mt-6">
              <a
                className="bg-mka-primary uppercase font-bold hover:bg-mka-primary-100 focus:outline-none  text-white py-2 px-6 rounded-full  inline-block "
                href="/"
              >
                Nhận LUCK
              </a>
            </div>
          </div>
          <div className="col-span-1 " />
        </div>
      </div>
    </div>
  );
}

export default Hero;
