import logoKardia from 'assets/images/partner/kardiachain.svg';
import iconFaqBullet from 'assets/images/iconFaqBullet.svg';
import style from './style.module.scss';

function PartnerItem() {
  return (
    <div className="bg-gray-100 p-3 mb-5 table align-middle">
      <div className="w-[320px] h-[150px] mx-auto table-cell align-middle p-3">
        <img
          src={logoKardia}
          className="w-auto h-[24px] mx-auto"
          alt="logo"
        />
      </div>
    </div>
  );
}

function Partner() {
  const partnersData = [
    {
      id: 1,
      title: 'BOOK token là gì?',
    },
    {
      id: 2,
      title: 'Tôi có thể kiểm tra số lượng BOOK  đang lưu thông hay không?',
    },
    {
      id: 3,
      title: 'Làm cách nào để sở hữu BOOK token?',
    },
    {
      id: 4,
      title: 'Làm thế nào để cash-out (nhận tiền mặt) từ BOOK?',
    },
    {
      id: 5,
      title: 'Làm thế nào để cash-out (nhận tiền mặt) từ BOOK?',
    },
    {
      id: 6,
      title: 'Làm thế nào để cash-out (nhận tiền mặt) từ BOOK?',
    },
  ];

  return (
    <div className="w-full">
      <div
        className="container px-4 md:px-0 mx-auto relative pt-[50px] pb-[50px]"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1500"
      >
        <div className={style.header}>
          <img
            src={iconFaqBullet}
            width="30px"
            height="30px"
            alt="Bullet"
            className="mt-[5px]"
          />
          <div className="header-text ml-2 text-[33px] text-mka-primary font-bold">Đối tác</div>
        </div>
        <div className="mt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
            {partnersData.map(({ id }) => (
              <PartnerItem key={id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
